<template>
  <swipe-modal
    v-model="model"
    border-top-radius="8px"
    @open="open"
  >
    <slot name="modal-content"></slot>
  </swipe-modal>
</template>
<script>
//* https://www.npmjs.com/package/@takuma-ru/vue-swipe-modal
import { swipeModal } from '@takuma-ru/vue-swipe-modal';

export default {
  name: "SwipeableModalBottom",
  components: {
    swipeModal,
  },
  props: ["value"],
  computed: {
    model: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    //* Emit event when swipe modal is opened
    open() {
      this.$emit("open");
    },
  },
  watch: {
    model(val) {
      //* plugin has bug if props value == false (updated from parent), overflow-y on <html> element still 'hidden'
      if (val == false) document.documentElement.style.overflowY = 'auto';
    },
  }
}
</script>
<style scoped>
/* Set z-index to be in front of chat window */
.swipe-modal-takumaru-vue-swipe-modal {
  z-index: 1002;
}
</style>